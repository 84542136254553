import React from 'react'

import Button from './Button'

function Footer() {
  return (
    <footer>
      <div className='footer-wrapper'>
        <div>
          <h2>
            Turn your brand <br />
            into a great one.
          </h2>
          <Button styleClass='button-light' text="Let's talk" colour='#121212' />
        </div>
      </div>
      <div className='footer-wrapper'>
        <a href='https://www.linkedin.com/in/nicolelambon/' target='_blank' rel='noreferrer'>LinkedIn</a>
        <a href='https://github.com/Takaya213' target='_blank' rel='noreferrer'>Github</a>
      </div>
      <div className='footer-wrapper'>
        <div className='copyright'>
          <p>{new Date().getFullYear()} &copy; Copyright Nicole Lambon</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
