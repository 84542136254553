import React from 'react'

function Skills() {
  return (
    <div className='wrapper inner-padding'>
      <h2>Skills.</h2>
      <div className='inner-wrapper skills'>
        <div>
          <p>Design</p>
          <p>
            Figma (Design and Prototype)
            <br />
            User Flows
            <br />
            Information Architecture UX
            <br />
            Sitemaps
            <br />
            Accessibility
          </p>
        </div>
        <div>
          <p>Development</p>
          <p>
            HTML 5<br />
            CSS (Sass, XStyled)
            <br />
            jQuery
            <br />
            ReactJS
            <br />
            Craft CMS
            <br />
            Accessibility
            <br />
            Visual Studio Code
            <br />
            Visual Studio
          </p>
        </div>
      </div>
    </div>
  )
}

export default Skills
